/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './src/scss/style.scss'

   const existingScript = document.getElementById('gsq');
    if (!existingScript) {
      const script = document.createElement('script');
      script.innerHTML = "!function(g,s,q,r,d){r=g[r]=g[r]||function(){(r.q=r.q||[]).push(" +
        "arguments)};d=s.createElement(q);q=s.getElementsByTagName(q)[0];" +
        "d.src='//d1l6p2sc9645hc.cloudfront.net/tracker.js';q.parentNode." +
        "insertBefore(d,q)}(window,document,'script','_gs');" +
        "_gs('GSN-593904-M');" +
        "_gs('set', 'anonymizeIP', true);"; 
      script.id = 'gsq';
      document.body.appendChild(script);
    }

    const smgtag = document.getElementById('smgtag');
    if(!smgtag){
      const gtag = document.createElement('script');
      gtag.async = true;
      gtag.src = "https://www.googletagmanager.com/gtag/js?id=UA-122705620-1";
      gtag.id = "smgtag";
      document.body.appendChild(gtag);
    }

    const existingScriptGA = document.getElementById('ga');
    if (!existingScriptGA) {
    

      const script = document.createElement('script');
      script.innerHTML = "window.dataLayer = window.dataLayer || [];" +
        "function gtag(){dataLayer.push(arguments);}" +
        "gtag('js', new Date());" +
        "gtag('config', 'UA-122705620-1');";
      script.id = 'ga';
      document.body.appendChild(script);

    }
module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Stephen Mwanzi","short_name":"steve-mwanzi","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"standalone","description":"I’m Stephen Mwanzi, Software Engineer based in Nairobi, Kenya.","orientation":"portrait","icon":"src/assets/images/icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"335dfc42529dbaa85938ef68c75e6e86"},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-100},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1920},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
